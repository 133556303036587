// import moment from 'moment'

jQuery(document).ready(function ($) {
  $("#mitchell-tenpenny-not-today-form").on("submit", function (e) {
    // Prevent default browser actions on form submission.
    e.preventDefault();
    // Get all the form user submitted data and get it ready to be sent.
    var data = $(this).serialize();
    // POST all the data to the SMF submission endpoint URL.
    $.ajax({
      type: "POST",
      url: "https://subs.sonymusicfans.com/submit",
      dataType: "json",
      data: data,
      xhrFields: {
        withCredentials: false
      },
      success: function (data) {
        // Do things on successful submission.
        alert("Submitted!");
      },
      error: function (err) {
        // Do things when submission has an error.
        alert("An error has occured!");
      }
    });
  });

  $('body').on('change', '.mailing-list-id', function () {
    $("#ts-for-" + $(this).attr('id')).prop("checked", $(this).is(':checked'));
  });

  // MUSIC TOGGLE
  $('.play-btn').click(function () {
    let channel = $(this).data('channel');
    let playIcon = $(this).find('i');
    let currentAudio = $(`#audio${channel}`)[0];
    
    // Pause all other audio elements
    $('audio').each(function(){
        if (this !== currentAudio) {
            this.pause();
            this.currentTime = 0; // Reset audio to start
            // Reset play icon for other channels
            let otherChannel = $(this).attr('id').replace('audio', '');
            $(`a[data-channel="${otherChannel}"] i`).attr('class', 'fa-solid fa-play');
        }
    });

    // Play or pause the current audio
    if (currentAudio.paused) {
        currentAudio.play().catch(error => {
            // Handle the promise rejection (Chrome Autoplay Policy)
            console.log("Autoplay was prevented. Please interact with the page first.");
        });
        playIcon.attr('class', 'fa-solid fa-pause');
    } else {
        currentAudio.pause();
        currentAudio.currentTime = 0; // Reset audio to start
        playIcon.attr('class', 'fa-solid fa-play');
    }
});
});